import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

export default function PayProduct() {
    return (
        <section id="payproduct" className="faq section-bg">

      <div className="container">
        <div className="section-title">
          <h2>Details</h2>
          <h3>Some little detail about our <span>Products</span></h3>
          <br />
          <h4>Payroll management Software</h4>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-10">
            <ul className="faq-list">

              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq20">
                    Payroll software: A software that increases promptness with zero error
                    <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                </div>
                <div id="faq20" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-8.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <p>
                  An intelligent and agile software helps processing  payroll faster and easier. It fits for any number of employees and is equipped for any nature of payroll structure. The integrated module includes all  stages from  on-boarding to separation.
                  </p>
                  <ol>
                  <p>Attendance</p>
                  <p>Auto salary calculation</p>
                  <p>Payment & Reimbursement</p>
                  <p>Taxation & Investment</p>
                  <p>Full & Final settlement</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq21" className="collapsed question">Performance Management: A module that develop employees to grow with business.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq21" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-9.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <p>
                  A module captures system based goal setting,  performance tracking ,performance review and feedback. Setting up KRAs, KPIs, competencies as per the designations to plan their own goal sheet.
                  </p>
                  <ol>
                  <p>Goal Setting</p>
                  <p>KRA/ KPI framework</p>
                  <p>Performance Review matrix</p>
                  <p>Ready Report generated</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq22" className="collapsed question">Recruitment Management : A module helps to find right talent at right time.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq22" className="collapse" data-bs-parent=".faq-list">
                  <br />
                <img src="assets/img/portfolio/portfolio-3.jpg" height="200" alt="" className="img-fluid anime" />
                  <p>
                  A module to automate Hiring from sourcing of  best talent till On- Boarding, faster and smoother. Reduce the turn around time , increasing speed of approval process with functionally rich workflow to manage and tracking at every level.
                  </p>
                  <ol>
                  <p>Sourcing and screening</p>
                  <p>Interview & Hiring</p>
                  <p>Approval Management</p>
                  <p>Generating offer & on boarding</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq23" className="collapsed question">ESS Module : A module helps to empower and engage employees through Employee Self Services.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq23" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-10.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <p>
                  Employee self-service (ESS) portals allow employees to directly access and update information in One click.
                  </p>
                  <ol>
                  <p>Dashboard with Employee details</p>
                  <p>Downloading Pay slips & Investment details</p>
                  <p>Leave and attendance management</p>
                  <p>Travel and Expenses Management</p>
                  <p>Enrolling in benefits</p>
                  <p>Company policies</p>
                  <p>Announcements</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq24" className="collapsed question">Travel Management : A module helps to find right talent at right time.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq24" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-11.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <p>
                  A module to automate Travel management including travel advance, budget approval process  faster and easier from application to payment.
                  </p>
                  <ol>
                  <p>Submit Travel request online</p>
                  <p>Online approval</p>
                  <p>Expense claim process</p>
                  <p>Submit and later track the status of expense claims online</p>
                  </ol>
                </div>
              </li>
              <li>
                    <div data-bs-toggle="collapse" href="#faq25" className="collapsed question">ESS Mobile Application : A module helps to access Employee Self Services anytime from anywhere on the go.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq25" className="collapse" data-bs-parent=".faq-list">
                      <br />
                      <video src="assets/img/portfolio/portfolio-12.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                      <ol>
                      <p>Employee would have  access  of their profile on mobile</p>
                      <p>Geo tagging and geo fencing feature for mobile attendance</p>
                      <p>Leave and attendance management</p>
                      <p>Expense Management</p>
                      <p>HR policy view on mobile application</p>
                      <p>Separation Management</p>
                      </ol>
                    </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </section>
    )
}
