import React from 'react'
import { Slide } from 'react-awesome-reveal'

export default function FAQSection() {
    return (
        <section id="faqit" className="faq section-bg">
          
      <div className="container" >
        <div className="section-title">
          <h4>IT Recruitment</h4>
        </div>
          <p>In order to respond to the increasing demands of manpower in the  IT industries. We have created a dedicated wing to cater to the requirements of the IT business with specialized recruiters having an in-depth experience of the latest development technologies in the IT industry.</p>

        <div className="row justify-content-center">
          <div className="col-xl-10">
            <ul className="faq-list">

              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq11">Return on investment<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq11" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <ul>
                    <li>Ensuring Right fitment for right Position from different channel </li>
                    <li>Lesser Drop-out & Attrition</li>
                    <li>Dedicated & experienced resource</li>
                    <li>Feedback sharing at regular interval</li>
                    <li>Ensured TAT</li>
                    <li>Pipeline for replacement</li>
                  </ul>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq12" className="collapsed question">IT Recruitment<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq12" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  The Peoplepro recruitment  is efficient to bridge the gap between right candidate and employers on right time.
                  </p>
                  <p>
                  The recruitment process involved steps from sourcing to on-boarding of the candidates. The hiring process involved stages as below.
                  </p>
                  <br />
                  <img src="assets/img/portfolio/portfolio-7.png" alt="" className="img-fluid anime" />
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq13" className="collapsed question">IT Staffing <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq13" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Peoplepro is a leading staffing solutions company in India. We provide quality manpower for a number of diverse industries. With the growing demand and the specialized requirement for the IT business we have this separate wing to provide the staffing solution to the IT business.
                  Peoplepro  helps with <b>permanent/contractual IT staffing</b> need by providing highly talented and experienced professionals to meet the strategic short term and long term requirements of organizations.
                  As a staffing solutions firm, we follow a single window approach, delivering the entire gamut of services. 
                  </p>
                  <br />
                  <ul>
                    <li>Understanding requirement of the company</li>
                    <li>Conducting interviews and shortlisting candidates</li>
                    <li>Shortlisted  potential candidates & background checks</li>
                    <li>Joining & Induction</li>
                    <li>Separation & Replacement if required</li>
                  </ul>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq14" className="collapsed question">Our overall value proposition includes<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq14" className="collapse" data-bs-parent=".faq-list">
                  <br />
                <ul>
                    <li>A National footprint.</li>
                    <li>Guaranteed TAT for every element of the service delivery.</li>
                    <li>Regulatory and Compliance adherence on a comprehensive basis.</li>
                    <li>Pan India  delivery capabilities.</li>
                    <li>Robust technology platform.</li>
                    <li>Strong and quality processes.</li>
                  </ul>
                  <h5>Temporary Staffing Operating Model</h5>
                  <img src="assets/img/portfolio/portfolio-5.jpg"  alt="" className="img-fluid anime" />
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
      
    </section>
    )
}
