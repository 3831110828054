import React from 'react'
import { Zoom } from 'react-awesome-reveal'

export default function ServiceSection() {
    return (
        <section id="services" className="services">

    <Zoom cascade triggerOnce>
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <h3>Check our <span>Services</span></h3>
          <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon"><i className="bx bxs-bank"></i></div>
              <h4><a href="">Pay roll as SAS</a></h4>
              <p>An integrated services for the Payroll from Attendance to Payment with  reports, analytics and tracking of process at any stages.</p>
                <br />
                <a href="#faqpay" className="btn btn-primary btn-buy">Check now</a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-building-house"></i></div>
              <h4><a href="">Compliance as SAS </a></h4>
              <p>Our platform is designed to  ground up and meet the needs of compliance for the company . The scale of our technology offerings suits enterprises of any scale and sizes.</p>
              <br />
              <a href="#faqcomp" className="btn btn-primary btn-buy">Check now</a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-briefcase-alt-2"></i></div>
              <h4><a href="">IT Recruitment</a></h4>
              <p>In order to respond to the increasing demands of manpower in the  IT industries. We have created a dedicated wing to cater to the requirements of the IT business with specialized recruiters having an in-depth experience of the latest development technologies in the IT industry.</p>
              <br />
              <a href="#faqit" className="btn btn-primary btn-buy">Check now</a>
            </div>
          </div>
        </div>
      </div>
      </Zoom>
      
    </section>
    )
}
