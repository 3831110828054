import React, {useRef, useState} from 'react';
import { Zoom  } from "react-awesome-reveal";
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactSection() {
  const [msg, setMsg] = useState(false);
  const [err, setErr] = useState(false);
  const [load, setLoad] = useState(false);
  const [validate, setvalidate] = useState();
  const recaptchaRef = useRef();

  function sendEmail(e) {
    e.preventDefault();
    setLoad(true); 
    emailjs.sendForm('service_hm6mad7', 'template_r28ke6j', e.target, 'user_6x5xFMd1zcSIcyiIYvgm2')
      .then((result) => {
          console.log(result.text);
          setMsg(true);
          setLoad(false);
      }, (error) => {
          console.log(error.text);
          setErr(true);
          setLoad(false);
      });
    e.target.reset();
  };

  function handlechange(value) {
    console.log("Captcha value:", value);
    setvalidate(value);
  };

    return (
        <section id="contact" className="contact">
      <Zoom triggerOnce>
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
          <h3><span>Contact Us</span></h3>
          <p>Want to get in touch? We`d love to here from you. Here`s how you can reach us...</p>
        </div>

        <div className="row">

          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>Unit No.-102, 1st Floor, Eco-Station, Plot No.-7, Block- BP , Salt Lake Sector-V, Kolkata- 700091, West Bengal.</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>info@peoplepro.co.in</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <p>+(91) (33) 40605685, 40605687</p>
            </div>
          </div>
        </div>

        <div className="row" >

          <div className="col-lg-6 ">
            <iframe className="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.2762519522917!2d88.42979361427362!3d22.56876893880039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275adbe153265%3A0x21556c94e4f91110!2sPeoplepro%20Management%20Services%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1630677226663!5m2!1sen!2sin" frameBorder="0" style={{border:0, width: '100%', height: '384px'}} allowFullScreen></iframe>
          </div>

          <div className="col-lg-6">
            <form method="post" role="form" className="php-email-form" onSubmit={sendEmail}>
              <div className="row">
                <div className="col form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              {msg ?
              <div>
                  <div class="alert alert-success" role="alert"> Your message has been sent. Thank you! </div>
              <br />
              </div>
              : null}
              {err ?
              <div>
              <div class="alert alert-danger" role="alert"> Some error occurred. Please try agin! </div>
              <br />
              </div>
              : null}
              <ReCAPTCHA
                 sitekey="6LdkEmAgAAAAAA8JkUcXzxqkKSGlrhS2fxZqA0pd"
                 onChange={handlechange}
                 ref={recaptchaRef}
               />
               { !load ?
              <div className="text-center">
              {validate ? <button type="submit" className="mt-2"> Send Message </button>
                  : <button type="submit" disabled style={{background: "#c5c5c5"}} className="mt-2"> Send Message </button>
              }
              </div>
              :
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              }
              
            </form>
          </div>
        </div>
      </div>
      </Zoom>
    </section>
    )
}
