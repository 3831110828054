import React from 'react'
import { Slide, Fade } from 'react-awesome-reveal'

export default function PayrollFAQ() {
    return (
        <section id="faqpay" className="faq section-bg">

      <div className="container">
        <div className="section-title">
          <h2>Details</h2>
          <h3>Some little detail about our <span>Services</span></h3>
          <br />
          <h4> Payroll SAS  Features</h4>
        </div>
          <p>An end to end  payroll processing software to manage your payroll accurate, on –time and hustle free.  Our expert team will take care of the Payroll software and make  your hard work easy so that you can spend time on your business strategies. </p>

        <div className="row justify-content-center">
          <div className="col-xl-10">
            <ul className="faq-list">

              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">Integrated payroll management Software <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Hustle free payroll management on-time and accurate managing from payroll processing, Leave ,statutory  reports to taxation & Investment to exit Formation.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Leave and over-time management <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Managing all kind of Leave , starting from CL,EL,ML, Compensatory Leave etc., as per the company policy in one click to support companies plan for advanced leave or tracking of Monthly/yearly Leave.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">Attendance <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Managing monthly attendance, <b>biometric</b>, Over time, flexi working, shift managing, On duty , <b>Work from home</b> , attendance processing etc. irrespective of locations, branch and offices.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Taxation & Investment<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Managing Taxation , benefit and investment of employees including TDS  projection, auto calculation, return & on line Callan, Tax planner  Form 16 & 12 B , investment declaration and proof submission.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">ESS (Employee Self Services) <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Managing employee profile, leave management, Attendance Management with Geo tagging ,Tax declaration, Policy and pay slip generation in one click etc.
                  </p>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
      
    </section>
    )
}
