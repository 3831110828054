import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import TopBar from "./component/TopBar";
import NavBar from "./component/NavBar";
import React, { Fragment } from "react";
import HeroHeader from "./component/HeroHeader";
import Featured from "./component/Featured";
import About from "./component/About";
import ChatBot from 'react-simple-chatbot';
import CountSection from "./component/CountSection";
import ServiceSection from "./component/ServiceSection";
import PriceSection from "./component/PriceSection";
import PayProduct from "./component/PayProduct";
import CompProduct from "./component/CompProduct";
import ClientSection from "./component/ClientSection";
import ContactSection from "./component/ContactSection";
import PayrollFAQ from "./component/PayrollFAQ";
import ComplianceFAQ from "./component/ComplianceFAQ";
import ITFAQ from "./component/ITFAQ";
import Footer from "./component/Footer";
import emailjs from 'emailjs-com';
import Privacy from "./component/Privacy";
import Policybar from "./component/Policybar";

function App() {

 const steps = [
   {
    id: '1',
    message: 'Hi! this is Pro, Do you need any help?',
    trigger: '2',
  },
   {
    id: '2',
    options: [
      { value: 'yes', label: 'Yes', trigger: '3' },
      { value: 'no', label: 'No', trigger: '20' },
    ]
  },
  {
    id: '3',
    message: 'Please select an option from below',
    trigger: '4',
  },
  {
    id: '4',
    options: [
      { value: 'Demo', label: 'Book a Demo', trigger: '5' },
      { value: 'Partner', label: 'Become a Partner', trigger: '5' },
      { value: 'Join', label: 'Join PeoplePro Group', trigger: '5' },
      { value: 'Price', label: 'Pricing?', trigger: '5' },
    ]
  },
  {
    id: '5',
    message: 'What is your name?',
    trigger: 'name',
  },
  {
    id: 'name',
    user: true,
    trigger: '6',
  },
  {
    id: '6',
    message: 'your company name?',
    trigger: 'companyname',
  },
  {
    id: 'companyname',
    user: true,
    trigger: '7',
  },
  {
    id: '7',
    message: 'Your contact number please.',
    trigger: 'number',
  },
  {
    id: 'number',
    user: true,
    trigger: '8',
    validator: (value) => {
      if (isNaN(value)) {
        return 'value must be a number';
      } else if (value.match(/^[0-9]{0,9}$/)) {
        return `${value}? Come on!`;
      }
      return true;
    },
  },
  {
    id: '8',
    message: 'Thanks! for choosing us. Any of our team-mate contact you shortly',
    end: true,
  },
  {
    id: '20',
    message: 'Ok',
    trigger: '7'
  },
  {
    id: '21',
    message: 'Can i help you with anything else? ',
    trigger: '2'
  },
];

function handleEnd({ values }) {
   console.log(values);

   var templateParams = {
    type: values[1],
    name: values[2],
    compname: values[3],
    mobile: values[4],
}
 
emailjs.send('service_hm6mad7', 'template_lwfn3be', templateParams, 'user_6x5xFMd1zcSIcyiIYvgm2')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
  
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <Fragment>
            <NavBar />
            <HeroHeader />
              <main id="main">
              <Featured />
              <About />
              <CountSection />
              <ClientSection />
              <PriceSection />
              <PayProduct />
              <CompProduct />
              <ServiceSection />
              <PayrollFAQ />
              <ComplianceFAQ />
              <ITFAQ />
              <ContactSection />
              <Footer />
            </main>
            <ChatBot
              handleEnd={(value)=>handleEnd(value)}
              floating = "true"
              steps={steps}
            />
        </Fragment>
          } />
          <Route path="privacy" element={
            <Fragment>
              <Policybar />
              <main id="main">
              <Privacy />
              <Footer />
            </main>
            </Fragment>
              } />
      </Routes>
    </BrowserRouter>


    
  );
}

export default App;
