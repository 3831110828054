import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Zoom } from "react-awesome-reveal";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

export default function ClientSection() {
    return (
        <section id="clients" className="clients section-bg">
        <Zoom triggerOnce>
        <div className="section-title">
          <h2>Trusted By</h2>
          </div>
          <div className="row">
  
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={500}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={300}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
          dotListClass="custom-dot-list-style"
          
          >
  <div><img src="assets/img/clients/Picture2.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture3.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture4.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture5.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture6.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture7.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture8.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture9.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture10.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture11.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture12.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture13.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture14.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture15.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture17.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture18.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture19.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture20.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture21.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture22.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture23.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture24.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture25.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture26.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture27.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture28.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture29.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture30.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture31.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture32.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture33.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture34.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture35.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture36.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture37.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture38.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture39.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture40.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture41.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture42.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture43.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture44.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture45.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture46.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture47.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture48.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture49.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture50.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture51.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture52.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture53.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture54.png" className="img-fluid" alt="" /></div>
  <div><img src="assets/img/clients/Picture55.png" className="img-fluid" alt="" /></div>
        </Carousel>
      </div>
    </Zoom>
  </section>
    )
}
