import React, {useState} from 'react';
import ScrollspyNav from "react-scrollspy-nav";


export default function Policybar() {
  const [open, setOpen] = useState(false);

  // java script writen on main.js
  //on('click', '.mobile-nav-toggle', function(e) {
  //   select('#navbar').classList.toggle('navbar-mobile')
  //   this.classList.toggle('bi-list')
  //   this.classList.toggle('bi-x')
  // })

  // /**
  //  * Mobile nav dropdowns activate
  //  */
  // on('click', '.navbar .dropdown > a', function(e) {
  //   if (select('#navbar').classList.contains('navbar-mobile')) {
  //     // e.preventDefault()
  //     this.nextElementSibling.classList.toggle('dropdown-active')
  //   }
  // }, true)

  //   /**
  //  * Scrool with ofset on links with a class name .scrollto
  //  */
  //    on('click', '.scrollto', function(e) {
  //     if (select(this.hash)) {
  //       e.preventDefault()
  
  //       let navbar = select('#navbar')
  //       if (navbar.classList.contains('navbar-mobile')) {
  //         navbar.classList.remove('navbar-mobile')
  //         let navbarToggle = select('.mobile-nav-toggle')
  //         navbarToggle.classList.toggle('bi-list')
  //         navbarToggle.classList.toggle('bi-x')
  //       }
  //       scrollto(this.hash)
  //     }
  //   }, true)
  
    return (
        
        <header id="header" className="d-flex align-items-center fixed-top">
          <div className="container d-flex align-items-center justify-content-between">
            {/* <h1 className="logo"><a href="index.html">PeoplePro Technologies<span>.</span></a></h1> */}
        <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" /></a>
          </div>
        </header>
    )
}
