import React from 'react'
import { Slide, Zoom  } from "react-awesome-reveal";

export default function HeroHeader() {
    return (
        <section id="hero" className="d-flex align-items-center">
      <Zoom triggerOnce>
    <div className="container">
      <h1>Welcome to <span className="herotext">Peoplepro <span style={{color: '#eb2525'}}>Technologies</span></span></h1>
      <h3 className="herotext">Hiring to Retiring , One Hundred percent HR automation software</h3>
      <br />
      <h4>Manage your business easier and simpler, stay on-time & complaint</h4>
      <div className="d-flex">
        <a href="#about" className="btn-get-started scrollto">Get Started</a>
        {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
      </div>
    </div>
      </Zoom >
  </section>
    )
}
