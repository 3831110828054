import React from 'react'
import { Slide } from 'react-awesome-reveal'

export default function CompProduct() {
    return (
        <section id="compproduct" className="faq section-bg">
      <div className="container">

        <div className="section-title">
          <h4>Compliance management Software</h4>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-10">
            <ul className="faq-list">

              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq30">
                Compliance Management: A compliance managing platform to accomplish all your statutory compliance through on-line portal.
                    <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                </div>
                <div id="faq30" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-13.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <ol>
                  <p>Log in Access to Client </p>
                  <p>Compliance Management</p>
                  <p>Dashboard with overview of business/branch wise ,compliance completed, due and on progress</p>
                  <p>Compliance calendar</p>
                  <p>Approval, Alerts & Trigger</p>
                  <p>Document Library</p>
                  <p>Auto generated report</p>
                  <p>Download certificate</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq31" className="collapsed question">Audit module drives our valued clients in the right direction, enabling better decision making, based on  clients' needs and always stay compliant as per rules applicable.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq31" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-14.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <ol>
                  <p>Conduct audit and critically analyse to create strong corporate governance</p>
                  <p>Graphical interpretation of High risk /Low risk/ Medium Risk</p>
                  <p>On-line report generation</p>
                  <p>Virtual Audit</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq32" className="collapsed question">ACT Library<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq32" className="collapse" data-bs-parent=".faq-list">
                  <br />
                  <video src="assets/img/portfolio/portfolio-15.mp4" autoPlay playsInline muted loop title="Payroll software" className="img-fluid anime"></video>
                  <p>
                  ACT Library is a complete repository of all the ACTs and the Rules as per business nature, state applicability for your convenience to check any kind of applicability for your business.
                  </p>
                  <ol>
                  <p>Name of Acts and Rules applicable in  states and central level</p>
                  <p>Required rules and regulations to be applicable as per the nature of the business</p>
                  <p>Documents and activities required  to stay complaint </p>
                  <p>Display of ACT  in regional language (English/state language) </p>
                  <p>Downloadable report  as per company policy.</p>
                  </ol>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq33" className="collapsed question">Advisory Services.<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq33" className="collapse" data-bs-parent=".faq-list">
                  <br />
                <img src="assets/img/portfolio/portfolio-3.jpg" alt="" className="img-fluid anime" />
                  <p>
                  Offer specialized consultation as per client requirement  to create strong corporate governance. Our expert guide provide  relevant suggestions, appropriate interpretations. We highlight the market practice which will enable you to make rational and informed decisions.
                  </p>
                  <ol>
                  <p>Registration as per applicability of laws under all acts labour/factory Law. </p>
                  <p>Guidance on the statutory format and policies to be prepared.</p>
                  <p>Regular audit (statutory, process, and correction audit).</p>
                  <p>Review of non-compliance report, risk analysis and advice wherever necessary.</p>
                  <p>Regularity or any other clarification if required.</p>
                  </ol>
                </div>
              </li>

              </ul>
          </div>
        </div>
        
      </div>
    </section>
    )
}
