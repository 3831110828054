import React from 'react'
import { Zoom, Fade } from "react-awesome-reveal";

export default function About() {
    return (
      <Zoom triggerOnce>  
        <section id="about" className="about section-bg">
        <div className="container">
            <div className="section-title">
            <h2>About</h2>
            <h3>Find Out More <span>About Us</span></h3>
            <p>Our Tag line Hiring to Retiring , One Hundred percent HR automation software .</p>
          </div>
  
          <div className="row">
            <div className="col-lg-6">
            <img src="assets/img/about.jpeg" className="img-fluid" alt="" />
              <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center">
              <ul>
                <li>
                  <div>
                    <h4>Why us</h4>
                    <ul>
                    <li><i className='bx bx-check-square'></i>User Friendly</li>
                    <li><i className="bx bx-checkbox-square"></i>Latest Technology</li>
                    <li><i className='bx bx-check-square'></i>Cost effective</li>
                    <li><i className="bx bx-checkbox-square"></i>Quick & Easy</li>
                    <li><i className='bx bx-check-square'></i>100 percent accurate</li>
                  </ul>
                  </div>
                </li>
              </ul>
              </div>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center">
              <h3>About PeoplePro Tecnologies.</h3>
              <p className="fst-italic">
              Peoplpro Technologies Pvt Ltd is into software business for payroll and compliance since 2018. As a Peoplepro Management  company,  Peoplepro  Technology Ltd starts its software business with a vision to provide quality software solution to enhance support to clients and making their work easy to manage.Peoplepro is in the business of HR Services under which it has web based as well as SAAS based HRIS solution which caters to Employee Life Cycle Management from ‘Hiring to Retirement’.The integrated solution for Employees, Human Resource and Payroll Management team with customized dashboard and detailed HR analytics  help you attain all the listed goals and expedite your Human Resource Actions.PTPL offers you a Module Based Solution that targets each stage of Employee-Lifecycle from Employee On-boarding to Separation. With excellent data capture to data analysis, this robust HR suite increases the transparency amongst the employees, thus empowering them in their day-to-day functioning. PTPL offers customized dashboards and comprehensive reports that help HR team to function as an efficient Strategic Business Partner than just managing HR operations online..
              </p>  
              <br />              
              <h3>About PeoplePro Group.</h3>
              <p>
              Peoplepro Management Services, founded in 2008, provides the finest professional services to the world of recruitment, headhunting, and HR process outsourcing with the most innovating technology-driven solutions. Our experienced team of professionals has expertise in a broad range of specialties including manpower outsourcing, recruitment (permanent & temporary), employee relations, and outsourcing of compliance management. We provide strategic HR solutions aligned with business potential through ongoing support, consultation services, HR staffing solutions. We provide quick and tailored services to help you manage your business easily and effortlessly. With our Pan India presence, we have our Corporate Office in Kolkata and its regional offices in New Delhi, Mumbai, Bangalore, Bhubaneswar, and Guwahati.  Peoplepro's association with over 200 organizations has many success stories to tell.We have the perfect blend of people and services, which allow us to cater to all your business strategy and manpower needs. To know how we can partner in consolidating your business growth, we are only a call or an email away!
              </p>  
            </div>
          </div>
        </div>
      </section>
      </Zoom>         
    )
}
