export default function FAQSection() {
    return (
        <section id="faqcomp" className="faq section-bg">

      <div className="container">
        <div className="section-title">
          <h4>Compliance SAS features</h4>
        </div>
          <p>This is a practical, easy to use platform with high security and protection. It allows you to fulfill your all compliance need in a single platform and stay complaint.</p>

        <div className="row justify-content-center">
          <div className="col-xl-10">
            <ul className="faq-list">

              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq7">Manage your compliance<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  End to end managing your compliance. Graphical presentation for your compliance status with approval matrix.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq8" className="collapsed question">Conduct audit  and analyses <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Compliance audit and follow up with the progress on the audit observations.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq9" className="collapsed question">Manage and review reports <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Manage downloadable reports and certificate from the system.
                  </p>
                </div>
              </li>

              <li>
                <div data-bs-toggle="collapse" href="#faq10" className="collapsed question">Provide alerts and metrics <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                <div id="faq10" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  Compliance calendar, and non compliance alerts would be triggered and pop –up mail as the matric for approval and completion.
                  </p>
                </div>
              </li>

            </ul>
          </div>
        
        </div>
      </div>
      
    </section>
    )
}
