import React from 'react'
import { Fade, Slide, Zoom } from 'react-awesome-reveal'

export default function Featured() {
    return (
        <section id="featured-services" className="featured-services">
          <Zoom triggerOnce>
          <div className="section-title">
            <h2>featured</h2>
            <h3>Our software <span>features</span></h3>
            <p>
            A truly comprehensive solution for the Payroll from attendance to 
Payment with  reports, analytics and tracking of process at any stages with An effective platform that helps managing regular compliance, risk assessment to achieve better governance on time and with full 
accountability.
            </p>
          </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className="bx bx-analyse"></i></div>
              <h4 className="title"><a href="">Smart Analytics</a></h4>
              <p className="description"><i className='bx bxs-hand-right'></i>Auto calculation</p>
              <p className="description"><i className='bx bxs-hand-right'></i>System generated reports</p>
              <p className="description"><i className='bx bxs-hand-right'></i>Stay atop on your payroll</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4 className="title"><a href="">100 % Data Security</a></h4>
              <p className="description"><i className='bx bxs-hand-right'></i>Ensure safe data keeping</p>
              <p className="description"><i className='bx bxs-hand-right'></i>Offer role-base access</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4 className="title"><a href="">seamless Data Integration</a></h4>
              <p className="description"><i className='bx bxs-hand-right'></i>Ensure smooth migration of data</p>
              <p className="description"><i className='bx bxs-hand-right'></i>Intigrated all HR tools for better result like leave, attendance etc.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="bx bx-user-voice"></i></div>
              <h4 className="title"><a href="">User Friendly</a></h4>
              <p className="description"><i className='bx bxs-hand-right'></i>Easy to manage</p>
              <p className="description"><i className='bx bxs-hand-right'></i>Save your time and stress</p>
            </div>
          </div>

        </div>
      </div>
      </Zoom>
    </section>
    )
}
