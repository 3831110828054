import CountUp from 'react-countup';
import { Zoom  } from "react-awesome-reveal";

export default function CountSection() {
  return (
    <section id="counts" className="counts">
      <Zoom triggerOnce>
      <div className="container">

        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="count-box">
              <i className="bi bi-emoji-smile"></i>
              <CountUp start={0} end={52} duration={2.75} suffix="+" delay={8} />
              <p>Happy Clients</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div className="count-box">
              <i className="bi bi-journal-richtext"></i>
              <CountUp start={0} end={9} duration={2} suffix="+" delay={8} />
              <p>Products</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="bi bi-headset"></i>
              <CountUp start={0} end={10} duration={2} suffix="+" delay={8} />
              <p>Hours Of Support</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="bi bi-people"></i>
              <CountUp start={0} end={30} duration={2.75} suffix="+" delay={8} />
              <p>Hard Workers</p>
            </div>
          </div>

        </div>
      </div>
      </Zoom>
    </section>
    )
}
