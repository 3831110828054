import React, {useState} from 'react';
import ScrollspyNav from "react-scrollspy-nav";


export default function NavBar() {
  const [open, setOpen] = useState(false);

  // java script writen on main.js
  //on('click', '.mobile-nav-toggle', function(e) {
  //   select('#navbar').classList.toggle('navbar-mobile')
  //   this.classList.toggle('bi-list')
  //   this.classList.toggle('bi-x')
  // })

  // /**
  //  * Mobile nav dropdowns activate
  //  */
  // on('click', '.navbar .dropdown > a', function(e) {
  //   if (select('#navbar').classList.contains('navbar-mobile')) {
  //     // e.preventDefault()
  //     this.nextElementSibling.classList.toggle('dropdown-active')
  //   }
  // }, true)

  //   /**
  //  * Scrool with ofset on links with a class name .scrollto
  //  */
  //    on('click', '.scrollto', function(e) {
  //     if (select(this.hash)) {
  //       e.preventDefault()
  
  //       let navbar = select('#navbar')
  //       if (navbar.classList.contains('navbar-mobile')) {
  //         navbar.classList.remove('navbar-mobile')
  //         let navbarToggle = select('.mobile-nav-toggle')
  //         navbarToggle.classList.toggle('bi-list')
  //         navbarToggle.classList.toggle('bi-x')
  //       }
  //       scrollto(this.hash)
  //     }
  //   }, true)
  
    return (
        
        <header id="header" className="d-flex align-items-center fixed-top">
          <div className="container d-flex align-items-center justify-content-between">
            {/* <h1 className="logo"><a href="index.html">PeoplePro Technologies<span>.</span></a></h1> */}
        <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" /></a>

             <ScrollspyNav 
                    scrollTargetIds={["hero", "featured-services", "about", "pricing", "services", "contact"]}
                    offset={10}
                    activeNavClass="active"
                    scrollDuration="500"
                    headerBackground="true"
                >
            <nav id="navbar" className= {open ? "navbar navbar-mobile" : "navbar"}>
              <ul>
                <li><a className="nav-link scrollto" href="#hero" onClick={()=>setOpen(false)}>Home</a></li>
                <li><a className="nav-link scrollto" href="#featured-services" onClick={()=>setOpen(false)}>Feature</a></li>
                <li><a className="nav-link scrollto" href="#about" onClick={()=>setOpen(false)}>About</a></li>
                <li><a className="nav-link scrollto" href="#pricing" onClick={()=>setOpen(false)}>Products</a></li>
                <li><a className="nav-link scrollto" href="#services" onClick={()=>setOpen(false)}>Services</a></li>
                {/* <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
                  <ul>
                    <li><a href="#">Drop Down 1</a></li>
                    <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                      <ul>
                        <li><a href="#">Deep Drop Down 1</a></li>
                        <li><a href="#">Deep Drop Down 2</a></li>
                        <li><a href="#">Deep Drop Down 3</a></li>
                        <li><a href="#">Deep Drop Down 4</a></li>
                        <li><a href="#">Deep Drop Down 5</a></li>
                      </ul>
                    </li>
                    <li><a href="#">Drop Down 2</a></li>
                    <li><a href="#">Drop Down 3</a></li>
                    <li><a href="#">Drop Down 4</a></li>
                  </ul>
                </li> */}
                <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
              </ul>
              <i className= {open ? "bi bi-x mobile-nav-toggle" : "bi bi-list mobile-nav-toggle" }
                onClick={()=>setOpen(!open)}></i>
            </nav>
              </ScrollspyNav>
          </div>
        </header>
    )
}
