import React from 'react'
import { Slide, Zoom } from 'react-awesome-reveal'

export default function PriceSection() {
    return (
        <section id="pricing" className="pricing">
    <Zoom triggerOnce>
      <div className="container">
        <div className="section-title">
          <h2>Procucts</h2>
          <h3>Check our <span>Products</span></h3>
          <p>
            A truly comprehensive solution for the Payroll from attendance to Payment with  reports and An integrated effective platform that helps managing regular compliance, risk assessment to achieve better Governance.
          </p>
        </div>
        <div className="row">
        <div className="col-lg-2">
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box featured">
              <h3>Payroll management</h3>
              <img src="assets/img/portfolio/portfolio-6.png" className="img-fluid" alt="" />
              <br />
              <br />
              <ul>
                <li>Payroll process</li>
                <li>PMS Module</li>
                <li>Recruitment Module</li>
                <li>ESS Module</li>
                <li>Travel Management</li>
                <li>ESS Mobile APP</li>
              </ul>
              <div className="btn-wrap">
                <a href="#payproduct" className="btn-buy">Check now</a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div className="box featured">
            <h3>Compliance management</h3>
            <img src="assets/img/portfolio/portfolio-4.png" className="img-fluid" alt="" />
              <br />
              <br />
              <ul>
                <li>Monthly compliance </li>
                <li>HR audit</li>
              </ul>
              <div className="btn-wrap">
                <a href="#compproduct" className="btn-buy">Check now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Zoom>
  </section>
    )
}
